import Vue from 'vue';
import App from './App.vue';
import core from '@bmp-vue/core';
import tools from '@/plugins/util/tools';
import DatePicker from '../src/components/Datepicker.vue';

Vue.config.productionTip = false;

var controllers = require.context('@/controllers', true, /\.js$/);
const paths = require('@/plugins/router/paths').default;
const theme = require('@/plugins/vuetify/theme').default;

const extendRouterBeforeEach = (vm) => {
  var empresa = vm.$store.getters.empresa;
  return empresa && empresa.codigo;
};

const clearNotification = () => {
  var html = document.querySelector('.vue-notification-group');
  html.innerHTML ='';
  html.appendChild(document.createElement('span'));
};

Vue.use(tools);

Vue.use(core, {
  controllers,
  paths,
  theme,
  defaultViews: false,
  carregaEnums: false,
  extendRouterBeforeEach,
  mount: (options) => {
    Vue.component('DatePicker', DatePicker);

    Vue.prototype.$clearNotification = clearNotification;

    new Vue({
      ...options,
      render: h => h(App)
    }).$mount('#app');
  }
});

Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('input', e => {
      e.target.value = e.target.value.toUpperCase();
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase());
    });
  }
});

Vue.directive('disable-all', {
  bind: function(el, binding) {
    if (!binding.value) return;

    const tags = ['input', 'button', 'textarea', 'select'];
    
    tags.forEach(tagName => {
      const nodes = el.getElementsByTagName(tagName);
      for (const node of nodes) {
        node.disabled = true;
        node.classList.add('v-text-field');
      }
    });

    
    const nodes = el.getElementsByTagName('v-input');
    for (const node of nodes) {
      node.classList.add('v-text-field');
    }
  }
});