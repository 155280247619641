export default {
  install: (app) => {
    app.mixin({
      methods: {
        $temSomentePerfilPorRota: (that, perfil) => {
          const user = JSON.parse(localStorage.getItem('user'));
          const roles = Array.isArray(user.roles) ? user.roles : [user.roles];
        
          const contextRoles = roles.filter(role => that.$route.meta.roles.includes(role));
        
          const temSomentePerfil = 
            contextRoles.length == 1 && 
            contextRoles[0] == perfil;
        
          return temSomentePerfil;
        }
      }
    });
  }
};

