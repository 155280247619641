import api from './utilPartner';

const controller = 'LimitesDeCredito';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  Saldo: function (dto) {
    return api.Axios.GetParams(url + '/SaldoCliente', dto);
  },
  Extrato: function (dto) {
    return api.Axios.GetParams(url + '/Extrato', dto);
  },
  ListarLimiteTotalClientesPorEmpresa: function (dto) {
    return api.Axios.GetParams(url + '/ListarLimiteTotalClientesPorEmpresa', dto);
  },
  ListarLimiteTotalClientesPorEmpresaEProduto: function (dto) {
    return api.Axios.GetParams(url + '/ListarLimiteTotalClientesPorEmpresaEProduto', dto);
  },
  ListarPosicaoContratada: function (dto) {
    return api.Axios.GetParams(url + '/ListarLimiteTotalClientesPorEmpresaEProdutoEProposta', dto);
  },
  PosicaoContratadaDecomposicao: function (dto) {
    return api.Axios.GetParams(url + '/ListarLimiteTotalClientesPorEmpresaEProdutoEPropostaDecomposicao', dto);
  },
  ListarRelatorioAutomatico: function (dto) {
    return api.Axios.GetParams(url + '/ListarRelatorioAutomatico', dto);
  },
  ListarPorCodigoCliente: function(dto) {
    return api.Axios.GetParams(url + '/ListarPorCodigoCliente', dto);
  },
  Delete: function(codigo) {
    return api.Axios.Delete(url, codigo);
  }
});
