import perfil from '@/utils/perfil';

export default [
  {
    path: '/dashboard',
    view: 'Dashboard',
    dir: 'views/app/',
    menu: false,
    meta: {
    }
  },
  {
    title: 'Importação',
    icon: 'fas fa-upload',
    path: '/importacao',
    dir: 'views/app/Importacoes/',
    menu: true,
    meta: {
      roles: [perfil.Import, perfil.Analista, perfil.Gestor, perfil.Master]
    },
    items: [
      {
        title: 'Nova importação',
        path: '/novaImportacao',
        dir: 'NovaImportacao/',
        view: 'NovaImportacao',
        menu: true,
        meta: {
          roles: [perfil.Import, perfil.Analista, perfil.Gestor, perfil.Master]
        }
      },
      {
        path: '/ImportacoesRealizadas',
        title: 'Importações realizadas',
        view: 'ImportacoesRealizadas',
        dir: 'ImportacoesRealizadas/',
        menu: true,
        meta: {
          roles: [perfil.Analista, perfil.Gestor, perfil.Master]
        }
      },
    ]
  },
  {
    path: '/relatorios',
    icon: 'fas fa-scroll',
    title: 'Relatórios',
    view: 'Relatorios',
    dir: 'views/app/Relatorios/',
    menu: true,
    meta: {
      roles: [perfil.Report, perfil.Analista, perfil.Gestor, perfil.Master]
    }
  },
  {
    title: 'Alteração cadastral',
    icon: 'fas fa-file-signature',
    path: '/alteracaocadastral/',
    dir: 'views/app/AlteracaoCadastral/',
    menu: true,
    meta: {
      roles: [perfil.Gestor, perfil.Master]
    },
    items: [
      {
        title: 'Aprovação/recusa',
        path: 'aprovacaoRecusa',
        dir: 'Controle/',
        view: 'Controle',
        menu: true,
        meta: {
          roles: [perfil.Gestor, perfil.Master]
        }
      },
      {
        title: 'Histórico',
        path: 'historico',
        dir: 'Historico/',
        view: 'Historico',
        menu: true,
        meta: {
          roles: [perfil.Gestor, perfil.Master]
        }
      }
    ]
  },
  {
    path: '/cadastroscontratacoes',
    icon: 'fas fa-th-list',
    title: 'Cadastros e contratações',
    dir: 'views/app/CadastrosContratacoes/',
    menu: true,
    meta: {
      roles: [perfil.Gestor, perfil.Master, perfil.Vendedor]
    },
    items: [
      {
        path: '/clientescadastrados',
        title: 'Clientes cadastrados',
        view: 'ClientesCadastrados',
        dir: 'ClientesCadastrados/',
        menu: true,
        meta: {
          roles: [perfil.Gestor, perfil.Master, perfil.Vendedor]
        },
      },
      {
        title: 'Detalhes cliente',
        path: '/clientescadastrados/detalhes',
        view: 'ClientesCadastradosDetalhes',
        dir: 'ClientesCadastrados/',
        menu: false,
        meta: {
          roles: [perfil.Gestor, perfil.Master, perfil.Vendedor]
        }
      },
      {
        path: '/propostas',
        title: 'Propostas contratadas',
        view: 'PropostasContratadas',
        dir: 'PropostasContratadas/',
        menu: true,
        meta: {
          roles: [perfil.Gestor, perfil.Master, perfil.Vendedor]
        }
      },
      {
        path: '/propostas/detalhes',
        title: 'Detalhes proposta',
        view: 'PropostasContratadasDetalhes',
        dir: 'PropostasContratadas/',
        menu: false,
        meta: {
          roles: [perfil.Gestor, perfil.Master, perfil.Vendedor]
        },
      },
      {
        path: '/cancelarproposta',
        title: 'Cancelar proposta',
        view: 'CancelarProposta',
        dir: 'CancelarProposta/',
        menu: true,
        meta: {
          roles: [perfil.Gestor, perfil.Master]
        },
      },
    ]
  },
  {
    path: '/propostas',
    icon: 'far fa-list-alt',
    title: 'Propostas',
    dir: 'views/app/Propostas/',
    menu: true,
    meta: {
      roles: [perfil.Basic, perfil.Manager]
    },
    items: [
      {
        path: '',
        title: 'Propostas',
        view: 'Propostas',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager]
        }
      },
      {
        path: '/detalhes',
        title: 'Analisar Proposta',
        view: 'PropostasDetalhes',
        menu: false,
        meta: {
          roles: [perfil.Basic, perfil.Manager]
        }
      },
      {
        path: '/cancelarproposta',
        title: 'Cancelar proposta',
        view: 'CancelarProposta',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager, perfil.Master, perfil.Gestor]
        }
      }
    ]
  },
  {
    path: '/gestaoCadastroDeCallback',
    icon: 'fas fa-reply-all',
    title: 'Gestão de callback',
    dir: 'views/app/Callback/',
    menu: true,
    meta: {
      roles: [perfil.Basic, perfil.Manager],
    },
    items: [
      {
        path: '/historicoEnvios',
        title: 'Histórico de envios',
        view: 'HistoricoEnvios',
        dir: 'HistoricoEnvios/',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager]
        }
      },
      {
        path: '/reenviocallbacks',
        title: 'Reenvio de callbacks',
        view: 'ReenvioCallbacks',
        dir: 'ReenvioCallbacks/',
        menu: true,
        meta: {
          roles: [perfil.Manager]
        }
      },
    ]
  },
  {
    path: '/gestaoDeContratos',
    icon: 'fas fa-calendar-alt',
    title: 'Gestão de contratos',
    dir: '',
    menu: true,
    meta: {
      roles: [perfil.Basic, perfil.Manager, perfil.Gestor, perfil.Master],
    },
    items: [
      {
        path: '/visualizarContratos',
        title: 'Visualizar contratos',
        view: 'AgendaRecebivel',
        dir: 'views/app/AgendaRecebivel/',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager, perfil.Gestor, perfil.Master]
        }
      },
      {
        path: '/detalhes',
        title: '',
        view: 'AgendaRecebivelDetalhes',
        dir: 'views/app/AgendaRecebivel/',
        menu: false,
        meta: {
          roles: [perfil.Basic, perfil.Manager, perfil.Gestor, perfil.Master]
        }
      },
      {
        path: '/boletos',
        title: 'Boletos',
        view: '/Boletos',
        dir: 'views/app/AgendaRecebivel/Consultas/Boletos',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager, perfil.Gestor, perfil.Master]
        }
      },
      {
        path: '/callbackAgenda',
        title: 'Callback agenda',
        view: '/CallbackAgenda',
        dir: 'views/app/AgendaRecebivel/Consultas/CallbackAgenda',
        menu: true,
        meta: {
          roles: [perfil.Basic, perfil.Manager]
        }
      }
    ]
  },
  {
    path: '/formalizacaoDeGarantias',
    icon: 'fas fa-book-open',
    title: 'Formalização de garantias',
    view: 'DocumentosPendentes',
    dir: 'views/app/DocumentosPendentes/',
    menu: true,
    meta: {
      roles: [perfil.Basic, perfil.Manager]
    }
  },
  {
    path: '/gestaoDeAcessos',
    icon: 'person',
    title: 'Gestão de acessos',
    view: 'Funcionarios',
    dir: 'views/app/Funcionarios/',
    menu: true,
    meta: {
      roles: [perfil.Basic, perfil.Manager, perfil.Gestor, perfil.Master]
    }
  },
  {
    path: '/outrosServicos',
    title: 'Outros serviços',
    icon: 'fa-solid fa-list',
    menu: true,
    meta: {
      roles: [ perfil.Manager]
    },
    dir: '',
    items: [
      {
        path: '/limitesCredito',
        title: 'Limites de crédito',
        view: 'LimitesCredito',
        dir: 'views/app/LimitesCredito/',
        menu: true,
        meta: {
          roles: [perfil.Manager]
        }
      },
      {
        path: '/extrato',
        title: 'Extrato',
        view: 'Extrato',
        dir: 'views/app/LimitesCredito/',
        menu: false,
        meta: {
          roles: [perfil.Manager],
          isPrinting: true
        }
      },
      {
        path: '/agendaPagamento',
        title: 'Agenda de pagamento',
        view: 'AgendaPagamento',
        dir: 'views/app/AgendaPagamento/',
        menu: true,
        meta: {
          roles: [perfil.Manager]
        }
      }
    ]
  },
  {
    path: '/mensageria',
    icon: 'fas fa-envelope',
    title: 'Mensageria',
    dir: 'views/app/Mensageria/',
    menu: true,
    meta: {
      roles: [perfil.Manager]
    },
    items: [
      {
        path: '/blockList',
        title: 'Block List',
        view: 'BlockList',
        dir: 'BlockList/',
        menu: true,
        meta: {
          roles: [perfil.Manager]
        }
      }
    ]
  },
  {
    path: '/processos/ccbdigital/assinatura',
    title: 'Assinatura',
    view: 'Assinatura',
    dir: 'views/app/AssinaturaProposta/',
    menu: false,
    meta: {
      anonymousAuth: true,
    }
  },
  {
    path: '/processos/ccbdigital/assinatura',
    title: 'Assinar',
    view: 'Assinar',
    dir: 'views/app/AssinaturaProposta/',
    menu: false,
    meta: {
      anonymousAuth: true,
    }
  },
  {
    path: '/',
    view: 'Login',
    dir: 'views/',
    title: 'Login',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/partner',
    view: 'Partner',
    dir: 'views/',
    title: 'Partner',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/senha/lembrar',
    view: 'Lembrar',
    dir: 'views/',
    title: 'Lembrar',
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
  {
    path: '/senha/recuperar',
    view: 'Recuperar',
    dir: 'views/',
    title: 'Recuperar',
    params: true,
    menu: false,
    meta: {
      anonymousAuth: true
    }
  },
];
