import api from './utilPartner';

const controller = 'Proposta';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarDocumentoPendente: function (dto) {
    return api.Axios.GetParams(url + '/ListarDocumentoPendente', dto);
  },
  LocalizarRegistro: (dto, vendedor) => {
    const vendedorURL = vendedor ? 'PorVendedor' : '';

    return api.Axios.GetParams(url + '/LocalizarRegistro' + vendedorURL, dto);
  },
  ExisteRegistroPropostaByCodigoProposta: dto => {
    return api.Axios.GetParams(`${url}/ExisteRegistroPropostaByCodigoProposta`, dto);
  },
  LocalizarRegistroDocumentoPendente: dto => {
    return api.Axios.GetParams(`${url}/LocalizarRegistroDocumentoPendente`, dto);
  },
  BuscarCustosEServicosComplementaresDaProposta: dto => {
    return api.Axios.GetParams(`${url}/BuscarCustosEServicosComplementaresDaProposta`, dto);
  },
  Listar: function (dto, vendedor) {
    const vendedorURL = vendedor ? 'PorVendedor' : '';

    return api.Axios.GetParams(url + '/Listar' + vendedorURL, dto);
  },
  ListarPorVendedor: function (dto) {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  ListarParaContratar: function (dto) {
    return api.Axios.GetParams(url + '/ListarParaContratar', dto);
  },
  MarcarDocumentoNaoPendente: function (CodigoProposta, dto) {
    return api.Axios.Put(
      url + '/MarcarDocumentoNaoPendente',
      CodigoProposta,
      dto
    );
  },
  Cancelar: function (dto) {
    return api.Axios.Post(`${url}/Cancelar`, dto);
  }
});
