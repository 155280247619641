import api from './utilPartner';

const controller = 'Autenticacao';
const url = process.env.VUE_APP_ROOT_API + controller + '/';

export default {
  controller,
  Login(dto) {
    return api.Axios.Post(url + 'Login', dto, null, '_');
  },
  RefreshPartner: async function(dto, token) {
    const response = await api.Axios.Post(url + 'Refresh', dto, null, token);

    if (response?.expiresIn)
      response.expires = response.expiresIn;

    return response;
  },
  Logout() {
    return new Promise(resolve => {
      resolve('');
    });
  },
  RefreshToken() {
    const { refreshToken, token } = JSON.parse(localStorage.getItem('user'));
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    const dto = {
      token: refreshToken,
      codigoEmpresa: empresa.codigo
    };

    return this.RefreshPartner(dto, token)
      .catch(() => this.Logout());
  },
  ResetSenha(dto) {
    return api.Axios.Post(url + 'ResetSenha', dto);
  },
  ResetSenhaAdmin(dto) {
    return api.Axios.Post(url + 'ResetSenhaAdmin', dto);
  },
  ValidarTokenResetSenha(dto) {
    return api.Axios.Post(url + 'ValidarTokenResetSenha', dto);
  },
  SolicitarTokenResetSenha(dto) {
    dto.isPartner = true;

    return api.Axios.Post(url + 'SolicitarTokenResetSenha', dto);
  },
  GerarReferenceToken(dto) {
    return api.Axios.Post(url + 'GerarReferenceToken', dto);
  },
  RegistrarUsuario(dto) {
    return api.Axios.Post(url + 'RegistrarUsuario', dto);
  },
  GetLoginErrorMessage(data){
    switch(data.messages[0].code){
      case 'CTL-002':
        return {
          errorDetailCode: data.messages[0].code,
          errorDescription:  'Sua senha expirou',
          errorMessage: 'Para continuar, altere sua senha clicando em "alterar senha"',
        };
      case 'CTL-007':
        return {
          errorDetailCode: data.messages[0].code,
          errorDescription:  'Sua senha irá expirar em breve',
          errorMessage: 'Para alterar, clique em "alterar senha" ou continue o login',
        };
      default:
        return {
          errorDetailCode: data.messages[0].code,
          errorDescription: data.messages[0].code,
          errorMessage: data.messages[0].description,
        };
    }
  }
};
