import api from './utilPartner';

const controller = 'PessoaParceiroCustomizacao';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarPorCodigoCliente: function (dto) {
    return api.Axios.GetParams(`${url}/ListarPorCodigoCliente`, dto);
  },
  ListarAssinantesPorCodigoCliente: function (dto) {
    return api.Axios.GetParams(`${url}/ListarAssinantesPorCodigoCliente`, dto);
  },
  Delete: function(codigo) {
    return api.Axios.Delete(url, codigo);
  }
});
