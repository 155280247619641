import api from './utilPartner';

const controller = 'PessoaEndereco';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, api.GetCrud(controller, null), {
  ListarPorPessoa: function (dto) {
    return api.Axios.GetParams(url + '/ListarPorPessoa', dto);
  },
  Delete: function(codigo) {
    return api.Axios.Delete(url, codigo);
  }
});
