import util from '@bmp-vue/core/api/util';
import api from './utilPartner';

const controller = 'PropostaNotificacaoIntegracao';
const url = process.env.VUE_APP_ROOT_API + controller;
export default Object.assign({}, util.GetCrud(controller, null), {
  Listar: function (dto) {
    return api.Axios.GetParams(url + '/Listar', dto);
  },
  ReenviarPropostaCallback: function (dto) {
    return api.Axios.Post(url + '/ReenviarPropostaCallback', dto);
  },
  
});
